@import "src/functions";

$tile-size: 350px;

.container {
    position: relative;
    width: $tile-size;
    max-width: calc(100vw - 40px);
}

.link {
    text-decoration: none;
    color: #000;
}

.imageSlider {
    position: relative;
    width: $tile-size;
    max-width: calc(100vw - 40px);
    aspect-ratio: 1 / 1;
    background: #eee;
    border-radius: 15px;
    overflow: hidden;
    border: solid 1px #ccc;

    img {
        width: 100%;
        height: auto;
    }
}

.keyDetails {
    margin: 5px;
}

.details {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.roomCounts {
    display: flex;
    align-items: center;
}

.bedroomCount, .bathroomCount {
    display: flex;
    align-items: center;
    margin-left: 12px;

    svg {
        margin-right: 4px;
        fill: #999;
        width: 18px;
        height: 18px;
    }
}

.bedroomCount svg {
    width: 24px;
    height: 24px;
}

.bathroomCount svg {
    width: 24px;
    height: 20px;
}

.title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 5px;
}
