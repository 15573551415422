html {
    box-sizing: border-box;
}

*, *::before, *::after {
    box-sizing: inherit;
}

body {
    margin: 0;
    font-size: 16px;
    font-family: -apple-system,
                 BlinkMacSystemFont,
                 'Segoe UI',
                 'Roboto',
                 'Oxygen',
                 'Ubuntu',
                 'Cantarell',
                 'Fira Sans',
                 'Droid Sans',
                 'Helvetica Neue',
                 sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
