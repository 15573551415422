.container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

.spinner {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: solid 5px #666;
    border-bottom-color: transparent;

    animation: spin linear 750ms infinite;

    @keyframes spin {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
}

.label {
    font-size: 16px;
    margin-left: 10px;
}
