.categoryLink {
    display: flex;
    align-items: center;

    &:not(:first-child) {
        margin-left: 18px;
    }

    > svg {
        width: 30px;
        height: 30px;
        margin-right: 4px;
    }
}
