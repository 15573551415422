@import "src/functions";

.container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 60px;

    @include narrower-than(500px) {
        padding: 20px 20px;
    }

    &::after {
        display: block;
        content: "";
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 100%;
        height: 4px;
        background: linear-gradient(to bottom, #d9d9d9, rgba(0, 0, 0, 0));
    }
}

.searchInput {
    width: 600px;
    padding: 10px 20px;
    border: solid 1px #ccc;
    border-radius: 20px;

    @include narrower-than(1000px) {
        width: 400px;
    }

    @include narrower-than(800px) {
        width: 300px;
    }

    @include narrower-than(600px) {
        width: 200px;
    }
}

.profileMenuButton {
    margin: 0px;
    padding: 6px;
    background: none;
    border: none;
    cursor: pointer;

    > svg {
        display: block;
        width: 24px;
        height: 24px;
    }
}
