@import "src/functions";

.container {
    padding: 20px 60px;
    margin: 0px auto;

    @include narrower-than(500px) {
        padding: 20px 20px;
    }
}

.categoryLinks {
    display: flex;
    margin-bottom: 30px;
}

.listingContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
}
